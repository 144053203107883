import React from "react";
import classNames from "classnames";
import { Accordion } from "@molecules";
import { Container, Text } from "@atoms";

const AccordionList = ({
  bgColor,
  className: _className,
  copy,
  items,
  padding,
}) => {
  return (
    <section
      className={classNames(
        "relative z-20 py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white" || !bgColor,
        },
        _className
      )}
    >
      <Container variant="xs">
        {copy && (
          <div className="mb-12 block">
            <Text
              richText
              className={classNames(
                "text-center prose-p:text-lg lg:prose-p:text-xl",
                {
                  "prose-dark": bgColor === "midnight",
                }
              )}
            >
              {copy}
            </Text>
          </div>
        )}
        {items && (
          <div className="space-y-8">
            {items.map(item => {
              return <Accordion key={item.uid} column={false} {...item} />;
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

AccordionList.defaultProps = {};

export default AccordionList;
